import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    authenticated: false,
    user: null,
    roles: [],
    backConfig: {},
    // FRONTEND STATE
    addresses: [],
    customer: {},
    guess: {},
    allRestaurant: [],
    restaurantActive: {},
    allReservations: [],
    menuSinCatering: [],
    categoryMenuListSinCatering: [],
    categoryMenuConCatering: [],
    menuConCatering: [],
    productsManagement: [],
    transactionData: [],
    products: [],
    variants: [],
    categories: [],
    tax: {},
    allTaxes: [],
    shipping: {},
    cart: [],
    order: {},
    allOrders: [],
    allTickets: [],
    configuration: {},
    hasRating: false,
    device: {},
    staffName: '',
    staffId: '',
    restaurantId: '',
    walletConfig: {},
    subscribers: [],
    customerCredit: {},
    allCustomerCredit: [],
    staffHouseAccount: false,
    restaurantCustomers: [],
    allStaff: [],
    about: [],
    assetpickup: [],
    legalDocument: [],
    richText: [],
    digitalSignage: [],
    event: [],
    isMerchant: false,
    footer: {},
    journalEntry: [],
    // Footer Defaults and User Custom Footers
    footerDefaults: {
      invoice:
        '<p>Thank you for your business! </p> <p>You may pay this invoice online by Clicking on the Pay Invoice button or you can send us a wire. Wire Details: Bank: XYZ Bank | Account No: 123-456-789 | SWIFT: XYZBANK</p> <p>For questions concerning this invoice, contact us at accounts@example.com or call us at +123 456 7890.</p>',
      quote:
        '<p>Please contact sales@example.com or call us at +123 456 7890.</p>',
      order:
        '<p>We appreciate your order and strive to fulfill it promptly. Should you have any questions, please email service@example.com or call +123 456 7890.</p>'
    },
    userFooters: {} // This will store user's customized footer texts
  },

  getters: {
    getFooterText: (state) => (type) => {
      return state.userFooters[type] || state.footerDefaults[type]
    }
  },

  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status
    },
    setUser(state, user) {
      state.user = user
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setRestaurantData(state, restaurantData) {
      state.backConfig.restaurant = restaurantData
    },
    setBackConfig(state, backConfig) {
      state.backConfig = backConfig
    },
    // FRONTEND MUTATION
    setCustomer(state, customer) {
      state.customer = customer
      state.guess = customer
    },
    setAddresses(state, addresses) {
      state.addresses = addresses
    },
    setGuess(state, guess) {
      state.guess = guess
    },
    setAllRestaurant(state, allRestaurant) {
      state.allRestaurant = allRestaurant
    },
    setAllReservations(state, allReservations) {
      state.allReservations = allReservations
    },
    setMenuSinCatering(state, menuSinCatering) {
      state.menuSinCatering = menuSinCatering
    },
    setCategoryMenuListSinCatering(state, categoryMenuListSinCatering) {
      state.categoryMenuListSinCatering = categoryMenuListSinCatering
    },
    setCategoryMenuConCatering(state, categoryMenuConCatering) {
      state.categoryMenuConCatering = categoryMenuConCatering
    },
    setMenuConCatering(state, menuConCatering) {
      state.menuConCatering = menuConCatering
    },
    setProducts(state, products) {
      state.products = products
    },
    setTransactionData(state, transactionData) {
      state.transactionData = transactionData
    },
    setProductsManagement(state, productsManagement) {
      state.productsManagement = productsManagement
    },
    setVariants(state, variants) {
      state.variants = variants
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setTax(state, tax) {
      state.tax = tax
    },
    setShipping(state, shipping) {
      state.shipping = shipping
    },
    setRestaurantActive(state, restaurantActive) {
      state.restaurantActive = restaurantActive
    },
    setCart(state, cart) {
      state.cart = cart
    },
    setOrder(state, order) {
      state.order = order
    },
    setAllOrders(state, allOrders) {
      state.allOrders = allOrders
    },
    setAllTickets(state, allTickets) {
      state.allTickets = allTickets
    },
    setConfiguration(state, configuration) {
      state.configuration = configuration
    },
    setHasRating(state, hasRating) {
      state.hasRating = hasRating
    },
    setDevice(state, device) {
      state.device = device
    },
    setStaffId(state, staffId) {
      state.staffId = staffId
    },
    setStaffName(state, staffName) {
      state.staffName = staffName
    },
    setRestaurantId(state, restaurantId) {
      state.restaurantId = restaurantId
    },
    setWalletConfig(state, walletConfig) {
      state.walletConfig = walletConfig
    },
    setSubscribers(state, subscribers) {
      state.subscribers = subscribers
    },
    setAllTaxes(state, allTaxes) {
      state.allTaxes = allTaxes
    },
    setCustomerCredit(state, customerCredit) {
      state.customerCredit = customerCredit
    },
    setAllCustomerCredit(state, allCustomerCredit) {
      state.allCustomerCredit = allCustomerCredit
    },
    setStaffHouseAccount(state, staffHouseAccount) {
      state.staffHouseAccount = staffHouseAccount
    },
    setRestaurantCustomers(state, restaurantCustomers) {
      state.restaurantCustomers = restaurantCustomers
    },
    setAllStaff(state, allStaff) {
      state.allStaff = allStaff
    },
    setAbout(state, about) {
      state.about = about
    },
    setAsset( state, assetpickup){
      state.assetpickup = assetpickup
    },
    setDigitalSignage(state, digitalSignage) {
      state.digitalSignage = digitalSignage
    },
    setEvent(state, event){
      state.event = event
    },
    setLegalDocument(state, legalDocument) {
      state.legalDocument = legalDocument
    },
    setRichText(state, richText){
      state.richText = richText
    },
    setIsMerchant(state, isMerchant) {
      state.isMerchant = isMerchant
    },
    setJournalEntry(state, journalEntry) {
      state.journalEntry = journalEntry
    },
    restartFront(state) {
      state.cart = []
      state.order = {}
      state.allOrders = []
      state.allReservations = []
      state.configuration = {}
      state.allTickets = []
      state.customerCredit = {}
      state.allCustomerCredit = []
      state.menuSinCatering = []
      state.categoryMenuListSinCatering = []
      state.categoryMenuConCatering = []
      state.menuConCatering = []
      state.products = []
      state.transactionData = []
      state.productsManagement = []
      state.variants = []
      state.categories = []
      state.tax = {}
      state.shipping = {}
      state.addresses = []
      state.restaurantActive = {}
      state.walletConfig = {}
      state.subscribers = []
      state.allTaxes = []
      state.staffHouseAccount = false
      state.restaurantCustomers = []
      state.about = []
      state.assetpickup = []
      state.digitalSignage = []
      state.event = []
      state.legalDocument = []
      state.richText = []
      state.journalEntry = []
      state.isMerchant = false
    },
    // Mutation to update the user's custom footer text
    updateFooterText(state, { type, text }) {
      state.userFooters = {
        ...state.userFooters,
        [type]: text
      }
    },
    setFooter(state, footer) {
      state.footer = footer
    }
  }
})

export default store
